import React from 'react';

const disc = (props) => {
  const discStatus = props.status;
  const players = props.players;
  const playerColors = props.playerColors;

  const getDiscClasses = stat => {
    let cl = "disc";
    if ((stat === 0) || (stat === 1)) {
      cl += " disc--player" + stat;
    }
    else if (stat === "legal") {
      cl += " disc--legal";
    }
    return cl;
  };

  const getDiscStyles = (stat, p) => {
    if ((stat === 0) || (stat === 1)) {
      const playerColor = p[stat].color;
      return ({backgroundColor: playerColors[playerColor]});
    }
    else {
      return null;
    }
  };


  return (
    <div className={getDiscClasses(discStatus)} style={getDiscStyles(discStatus, players)}></div>
  )
};

export default disc;