import React from 'react';

const footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__blurb u-margin-bottom-medium">
          <p><span className={"bold"}>Reversi: The Reversi-verse</span> v1.0 was designed and hand&#8209;coded by Brian&nbsp;Coppola using React, JavaScript ES6, Sass, CSS,&nbsp;and&nbsp;HTML.</p>
        </div>
        <div className="footer__copyright">
          <p>&copy; 2020 Brian Coppola Design</p>
        </div>
      </div>
    </footer>
  );
};

export default footer;