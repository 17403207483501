import React from 'react';

const moveAgain = (p, curr) => {
  const otherPlayer = (curr + 1) % 2;

  if (p[otherPlayer].legalMoves === 0) {
    return true;
  }
  else {
    return false;
  }
  
};

const status = (props) => {
  const players = props.players;
  const playerColors = props.playerColors;
  const currentPlayer = props.currentPlayer;
  const currentPlayerColor = players[currentPlayer].color;

  return (
    <div className="status">
      <h2 className="status__header u-margin-bottom-small">
        <span style={{color: playerColors[currentPlayerColor]}}>{players[currentPlayer].name}</span> to play {moveAgain(players, currentPlayer) ? "again!" : null}
      </h2>
    </div>
  )
};

export default status;