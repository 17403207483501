import React from 'react';

const aux = (props) => {
  if (props.divClass === undefined) {
    return props.children;
  }
  else {
    return (
      <div className={props.divClass}>
        {props.children}
      </div>
    )
  }
};

export default aux;