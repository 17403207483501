import React from 'react';
import Aux from '../../hoc/aux';

const setupPlayer = (props) => {
  const players = props.players;
  const playerColors = props.playerColors;
  const playerColorNames = Object.keys(playerColors);
  const nameChanged = props.nameChanged;
  const colorChanged = props.colorChanged;

  return (
    <Aux>
      {players.map((p, index) => {
        const playerID = p.id;
        const playerName = p.name;

        return (
          <div className={"setup__player"} key={playerID}>
            <h2>{playerName}</h2>
            <input
              className="choose-name"
              type="text"
              id={"p" + playerID + "-name"}
              name="fname"
              placeholder="Name"
              maxLength="20"
              onChange={(event) => nameChanged(event, playerID)}>
            </input>

            <div className="choose-color">
              {playerColorNames.map(pc => {     
                const radioName = "p" + index + "-color";  
                const discClass = radioName + "-" + pc;
                const otherPlayerID = (index === 0 ? 1 : 0);
                const otherPlayerColor = players[otherPlayerID].color;

                return (
                  <div key={pc}>
                    <input
                      type="radio"
                      name={radioName}
                      id={discClass}
                      value={pc}
                      onClick={(event) => colorChanged(event, playerID)}
                      disabled={pc === otherPlayerColor ? true : false} />
                    <label htmlFor={discClass}>
                      <div className="choose-color__disc" style={{backgroundColor: playerColors[pc]}}></div>
                    </label>
                  </div>
                )})
              }
            </div>
          </div>
        )})
      }
    </Aux>
  );
};

export default setupPlayer;