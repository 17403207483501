import React from 'react';

const main = (props) => {
  if (props.class === undefined) {
    return (
      <main className="main">
        {props.children}
      </main>
    )
  }
  else {
    const classes = "main " + props.class;
    return (
      <main className={classes}>
        {props.children}
      </main>
    )
  }
};

export default main;