import React from 'react';
import { Link } from "react-router-dom";

const header = () => {
  return (
    <header className="header">
      <div className="container">
        <h1><Link to="/reversi">Reversi: The&nbsp;Reversi&#8209;verse</Link></h1>
      </div>
    </header>
  );
};

export default header;