import React from 'react';
import { Link } from 'react-router-dom';
import Main from '../components/sections/main';
import iconSet from "../svg/sprite.json";
import IcomoonReact from "icomoon-react";

const header = (props) => {
  const history = props.history;

  return (
    <Main class="white">
      <div className="home container">
        <section className="home__header u-margin-bottom-medium">
          <h2>Welcome to the Reversi&#8209;verse!</h2>
        </section>
        <section className="home-ui u-margin-bottom-large">
          {/* {opened && (					
            <div class="boxContent">
              {children}
            </div>
          )} */}
          {/* <div className="home-ui__row">
            <div className="home-ui__button" onClick={() => { history.push("/pass-and-play"); }}>
              <div className="home-ui__button--disc home-ui__button--disc-1"><h2>New game pass &amp;&#160;play</h2></div>
            </div>
          </div> */}
          <div className="home-ui__row">
            <Link to="/reversi/pass-and-play" className="home-ui__button">
              <div className="home-ui__button--disc home-ui__button--disc-1"><h2>New game pass &amp;&#160;play</h2></div>
            </Link>
            <Link to="/reversi/rules" className="home-ui__button">
              <div className="home-ui__button--disc home-ui__button--disc-2"><h2>Rules</h2></div>
            </Link>
          </div>
        </section>
        <section className="home__description">
          <h2>What is Reversi?</h2>
          <p>Invented in England during toward the end of the 1800s, <span className="bold">Reversi</span> (or Othello) is an abstract strategy game played on an 8x8 grid with discs that are one color on one side and another color on the other side (normally white and black). Each player is assigned one of the two colors, and the objective is to end the game with more discs of your color face up than your opponent's&nbsp;color.</p>
          <p>My version (<span className="bold">The Reversi-verse</span>) is 'pass-and-play' where both players play in the same browser on the same computer or tablet. Future versions will include an AI&nbsp;opponent and networked multiplayer&nbsp;play.</p>
          <p>More on the <a href="https://en.wikipedia.org/wiki/Reversi" className="bold" target="_blank" rel="noopener noreferrer">Reversi Wikipedia page <IcomoonReact className="icomoon-icon" iconSet={iconSet} icon="new-tab" target="_blank" /></a></p>
        </section>
      </div>
    </Main> 
  );
};

export default header;