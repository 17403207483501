import React from 'react';
import Aux from '../../hoc/aux';
import Button from '../ui/button';

const gameSummary = (props) => {
  const players = props.players;
  const playerColors = props.playerColors;
  const leader = props.leader;
  const discCount = players
    .map(p => {
      return <li key={p.id}><span style={{color: playerColors[p.color]}}>{p.name}</span> &mdash; {p.discs}</li>
    });

  return (
    <Aux>
      <div className="modal__game-over">
        <h3>GAME OVER</h3>
        {players[0].discs + players[1].discs === 64 ? <h4>Board is full</h4> : <h4>No legal moves for either player</h4>}
      </div>
      {leader === null ?
        <h2>Game ends in a tie!</h2> :
        <h2><span style={{color: playerColors[players[leader].color]}}>{players[leader].name}</span> is the winner!</h2>
      }
      <h4>Final disc count:</h4>
      <ul>
        {discCount}
      </ul>
      <div className="modal__buttons">
        <Button clicked={() => props.clicked("home")}>Home page</Button>
        <Button clicked={() => props.clicked("new")}>Start new game</Button>
        <Button clicked={() => props.clicked("view")}>View game board</Button>
      </div>
    </Aux>
  )
};

export default gameSummary;