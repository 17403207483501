import React, { Component } from 'react';
import Disc from '../ui/disc';

class Square extends Component {
  constructor(props) {
    super();
    this.props = props;
  }
  
  getSquareClasses = sq => {
    let cl = "square";
    if (sq.status === "legal") {
      cl += " square--legal"
    }
    return cl;
  };
  
  squareClickHandler = (sq, moves) => {
    if (sq.status === "legal") {
      return () => moves.playDisc(sq);
    }
    else {
      return null;
    }
  };

  render() {
    const square = this.props.square;
    const squareID = square.id;
    const squareColors = this.props.squareColors;
    const squareColor = squareColors[square.color];
    const moves = this.props.moves;
    const discStatus = square.status;
    const players = this.props.players;
    const playerColors = this.props.playerColors;

    return (
      <div 
        id={squareID}
        key={squareID}
        className={this.getSquareClasses(square)}
        style={{backgroundColor: squareColor}}
        onClick={this.squareClickHandler(square, moves)}>
        { ((typeof discStatus === 'number') || (discStatus === "legal")) ? <Disc status={discStatus} players={players} playerColors={playerColors} /> : null}
      </div>
    )
  }
}

export default Square;