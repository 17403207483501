import React from 'react';

const table = (props) => {
  return (
    <div className="table">
      {props.children}
    </div>
  )
};

export default table;