import React from 'react';

const sidebar = (props) => {
  return (
    <main className="sidebar">
      <div className="sidebar__section scores">
        <h2 className="scores__title u-margin-bottom-small">Discs on board</h2>
        <div className="scores__list">
          {props.children}
        </div>
      </div>
    </main>
  )
};

export default sidebar;