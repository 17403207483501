import React from 'react';

const guide = (props) => {
  const players = props.players;
  // const playerColors = props.playerColors;
  const currentPlayer = props.currentPlayer;
  // const currentPlayerColor = players[currentPlayer].color;
  const legalMoves = players[currentPlayer].legalMoves;

  return (
    <div className="symbols">
      <div className="symbols__symbol">
        <div className="disc disc--legal"></div>
      </div>
      <div className="symbols__text">Small dots = {players[currentPlayer].name}'s' legal moves ({legalMoves})</div>
    </div>
  )
};

export default guide;