import React from 'react';
import Aux from '../../hoc/aux';
import Backdrop from './backdrop';

const modal = (props) => {
  return (
    <Aux>
      <Backdrop show={props.show} clicked={props.clicked} />
      <div className="modal" style={{ display: props.show === "end" ? 'block' : 'none' }}>
        {props.children}
      </div>
    </Aux>
  )
};

export default modal;