import React from 'react';
import { Switch, Route } from "react-router";
import Aux from "./hoc/aux";
import Header from './components/sections/header';
import Home from './containers/home';
import Game from './containers/Game';
import Rules from './containers/rules';
import Footer from './components/sections/footer';
import './App.css';

function App() {
  
  return (
    <Aux>
      <Header />
      <Switch>
        <Route
          path="/reversi"
          exact
          render={(props) => <Home {...props} />}
        />
        <Route
          path="/reversi/pass-and-play"
          exact
          render={(props) => <Game {...props} />}
        />
        <Route
          path="/reversi/rules"
          exact
          render={(props) => <Rules {...props} />}
        />
      </Switch>
      <Footer />
    </Aux>
  );
}

export default App;