import React from 'react';

const playerScores = (props) => {
  const players = props.players;
  const playerColors = props.playerColors;

  return (
    <div>
      {players.map(p => {
        const playerID = p.id;
        const divID = "scores__player" + playerID;
        const playerColor = playerColors[p.color];
        
        return (
          <div className="scores__player" id={divID} key={playerID}>
            <div className="scores__player-bar" style={{backgroundColor: playerColor}}></div>
            <p className="scores__player-name">{p.name}</p>
            <p className="scores__player-score">{p.discs}</p>
            <div className="scores__player-turn"></div>
          </div>
        )})
      }
    </div>
  )
};

export default playerScores;