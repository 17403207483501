import React from 'react';
import Main from '../components/sections/main';
import rules1 from '../img/rules_1.png';
import rules2 from '../img/rules_2.png';
import rules3 from '../img/rules_3.png';
import { Link } from "react-router-dom";
import iconSet from "../svg/sprite.json";
import IcomoonReact from "icomoon-react";

const rules = (props) => {
  // const history = props.history;

  return (
    <Main class="white">
      <div className="rules container">
        <section className="rules__content">
          <h2>Reversi rules</h2>
          <h3>Game setup</h3>
          <p>Each player starts with 32 two-sided discs, and two of those discs from each player are positioned on the board as follows in the image below.</p>
          <div className="rules__img">
            <img src={rules1} alt="[rules] Starting player positions" />
          </div>
          <p>During the game, the small gray dots indicate legal move locations for the current player (in this case, the blue player).</p>
        </section>
        <section className="rules__content">
          <h3>Game play</h3>
          <p>The starting player is chosen randomly. Players alternate taking turns throughout the game.</p>
          <p>Players have one move during their turn. They <span className="bold">must</span> play a disc from their supply with their color face-up. They also must play it in a board square where they can surround in a straight line at least one of their opponent's pieces with their own pieces (including diagonals). This is called <span className="bold">outflanking</span>.</p>
          <p>For example, in the images below, the orange player has surrounded three lines of blue pieces with orange pieces by placing a piece in location A.</p>
          <div className="rules__img">
            <img src={rules2} alt="[rules] Starting player positions" />
            <img src={rules3} alt="[rules] Starting player positions" />
          </div>
          <p>When pieces are 'outflanked' like this, all the outflanked pieces are flipped over to the current player's color. In this example, all the outflanked blue pieces have been flipped to the orange side.</p>
          <p>Outflanking can only happen in a straight line.</p>
        </section>
        <section className="rules__content">
          <h3>More on moves</h3>
          <ul>
            <li>If a player can not outflank at least one opponent's disc on their turn, they forfeit their turn and their opponent moves again. Players may not intentionally forfeit, however&mdash;if a legal move is available, players must take it.</li>
            <li>A played disc will never be flipped over on the turn it is placed on the board, even if it is placed in an outflanked position.</li>
          </ul>
        </section>
        <section className="rules__content">
          <h3>End of the game</h3>
          <p>The game play continues in this fashion until one of two events occurs:</p>
          <ul>
            <li>The board is full and has no more empty squares.</li>
            <li>Neither player has any legal moves. (In other words, there's no space on the board they can play a disc where they can outflank any of their opponent's pieces.)</li>
          </ul>
          <p>When either of those events happen, <span className="bold">the game is over</span>. The discs on the board are counted up according to color, and <span className="bold">the player with more discs on the board is the winner</span>. If the players have an equal number of discs on the board, the game ends in a tie (or&nbsp;draw).</p>
        </section>
        <section className="rules__content">
          <h3>Have fun and enjoy the game!</h3>
          <p><Link to="/reversi"><IcomoonReact className="icomoon-icon" iconSet={iconSet} icon="arrow-left" target="_blank" /> Return to home <IcomoonReact className="icomoon-icon" iconSet={iconSet} icon="home" target="_blank" /></Link></p>
        </section>
      </div>
    </Main> 
  );
};

export default rules;