import React, { useState, useEffect, myRef } from 'react';
import Square from './Square';

// const useResize = (myRef) => {
//   const [width, setWidth] = useState(0)
//   const [height, setHeight] = useState(0)

//   useEffect(() => {
//     const handleResize = () => {
//       setWidth(myRef.current.offsetWidth)
//       setHeight(myRef.current.offsetHeight)
//     }

//     window.addEventListener('resize', handleResize)

//     return () => {
//       window.removeEventListener('resize', handleResize)
//     }
//   }, [myRef])

//   return { width, height }
// }

const board = (props) => {
  // const componentRef = useRef();
  // const { width, height } = useResize(componentRef);

  return (
    <div className="board u-margin-bottom-small" ref={myRef}>
      {props.squares.map(sq => {
        const squareID = "square" + sq.id;
        
        return (
          <Square
            id={squareID}
            key={squareID}
            square={sq}
            squareColors={props.squareColors}
            players={props.players}
            playerColors={props.playerColors}
            currentPlayer={props.currentPlayer}
            moves={props.moves}
          />
        )})
      }
    </div>
  )
};

export default board;