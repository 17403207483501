import React from 'react';
import Button from '../ui/button';

const setup = (props) => {
  const submit = props.submit;

  return (
    <div className="setup container">
      <div className="setup__header u-margin-bottom-medium">
        <h2>Choose your names and disc&nbsp;colors:</h2>
      </div>
      <div className="setup__ui u-margin-bottom-large">
        {props.children}
      </div>
      <div className="setup__submit">
        <Button clicked={submit}>Start game</Button>
      </div>
      <div className="setup__alert">
        {props.alert === "setup color" &&
          <p>Both players need to select a color</p>
        }
      </div>
    </div>
  );
};

export default setup;