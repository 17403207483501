import React, { Component } from 'react';
import Aux from '../hoc/aux';
import Modal from '../components/ui/modal';
import GameSummary from '../components/info/gameSummary';
import Setup from '../components/setup/setup';
import SetupPlayer from '../components/setup/setupPlayer';
import Main from '../components/sections/main';
import Sidebar from '../components/sections/sidebar.js';
import PlayerScores from '../components/info/playerScores';
import Table from '../components/sections/table';
import Status from '../components/info/status';
import Board from '../components/board/board';
import Guide from '../components/info/guide';

const squareColors = {
  light: getComputedStyle(document.documentElement).getPropertyValue('--color-primary-dark-1'),
  dark: getComputedStyle(document.documentElement).getPropertyValue('--color-primary-dark-2')
};

const playerColors = {
  blue: getComputedStyle(document.documentElement).getPropertyValue('--color-ui-blue'),
  red: getComputedStyle(document.documentElement).getPropertyValue('--color-ui-red'),
  yellow: getComputedStyle(document.documentElement).getPropertyValue('--color-ui-yellow'),
  green: getComputedStyle(document.documentElement).getPropertyValue('--color-ui-green'),
  orange: getComputedStyle(document.documentElement).getPropertyValue('--color-ui-orange'),
  purple: getComputedStyle(document.documentElement).getPropertyValue('--color-ui-purple')
};

class Game extends Component {
  state = {
    mode: "setup",
    numPlayers: 2,
    get currentPlayer() {
      return Math.floor(Math.random() * this.numPlayers);
    },
    leader: null,
    players: new Array(2).fill(0).map((_, i) => ({
      id: i,
      name: "Player " + (i + 1),
      color: null,
      discs: 0,
      legalMoves: 1
    })),
    squares: new Array(64).fill(0).map((_, i) => ({
      id: i,
      row: Math.floor(i / 8) + 1,
      column: (i % 8) + 1,
      status: null,
      color: ((Math.floor(i / 8) + 1) + ((i % 8) + 1)) % 2 === 0 ? "light" : "dark"
    })),
    alert: null,
    stage: {
      onGameStart: () => {
        this.startGame();
      },
      onGameEnd: () => {
        this.endGame();
      }
    },
    turn: { 
      onBegin: () => {
        this.setLegalMoves(); // + checkGameEnd
      },
      onMove: () => {
        this.updateScore();
        this.determineLeader();
        this.cleanUpSquares();
        this.setNextPlayer();
      }
    },
    moves: {
      playDisc: (sq) => {
        this.playDisc(sq);
      }
    }
  }

  /* =============== */
  /* Setup functions */
  /* =============== */

  nameChangedHandler = (event, id) => {
    let players = this.state.players;
    players[id].name = event.target.value;

    this.setState({
      players: players
    });
  }

  colorChangedHandler = (event, id) => {
    let players = this.state.players;
    players[id].color = event.target.value;

    this.setState({
      players: players
    });
  }

  setupSubmitHandler = () => {
    const playersNoColor = this.state.players.filter(player => player.color === null)

    if (playersNoColor.length > 0) {
      this.setState({
        alert: "setup color"
      });
    } 
    else {
      this.setState({
        alert: null
      }, () => this.state.stage.onGameStart()) // callback executes after this.state.alert is updated
    }
  }

  /* ==================== */
  /* Game start functions */
  /* ==================== */

  startGame() {
    this.setState({
      mode: "play"
    });

    this.state.squares.forEach((sq) => {
      sq.status = null;

      if ((sq.id === 27) || (sq.id === 36)) {
        sq.status = 0;
      }
      if ((sq.id === 28) || (sq.id === 35)) {
        sq.status = 1;
      }
    });

    this.updateScore();
    this.state.turn.onBegin();
  }

  /* ============== */
  /* Game functions */
  /* ============== */

  updateScore = () => {
    let players = this.state.players;

    players.forEach(p => {
      const discs = this.state.squares.filter(sq => sq.status === p.id);
      p.discs = discs.length;
    });

    this.setState({
      players: players
    });
  }

  otherPlayer = (curr) => {
    return (curr + 1) % 2;
  }

  /* ======================= */
  /* Start of turn functions */
  /* ======================= */

  spaceOnBoard = (x, y) => {
    return x >= 1 && y >= 1 && x <= 8 && y <= 8;
  }

  getLegalMoves = (curr) => {
    const currentPlayer = curr;
    let legalMoves = 0;
    let legalMoveSpaces = [];

    this.state.squares.filter(sq => sq.status === null).forEach((sq) => {
      for (let i = -1; i <= 1; i++) {
        for (let j = -1; j <= 1; j++) {
    
          if ((i === 0 && j === 0)) {
            continue;
          }
    
          let currentX = sq.row + i;
          let currentY = sq.column + j;
    
          if (!this.spaceOnBoard(currentX, currentY)) {
            continue;
          }
    
          let currentID = ((currentX - 1) * 8) + currentY - 1;
          let currentSquare = this.state.squares[currentID];
    
          if (currentSquare.status === this.otherPlayer(currentPlayer)) {
            let x = 2;
    
            while (this.spaceOnBoard(currentX, currentY)) {
              if (currentSquare.status === currentPlayer) {
                if (legalMoveSpaces.indexOf(sq.id) === -1) {
                  legalMoves++;
                  legalMoveSpaces.push(sq.id);
                }
                sq.status = "legal";
                break;
              }
              else if ((currentSquare.status === null) || (currentSquare.status === "legal")) {
                break;
              }
      
              currentX = sq.row + (i * x);
              currentY = sq.column + (j * x);
    
              currentID = ((currentX - 1) * 8) + currentY - 1;
              currentSquare = this.state.squares[currentID];
    
              x++;
            }
          }
        }
      }
    });

    return legalMoves;
  }

  setLegalMoves = () => {
    let players = this.state.players;
    const currentPlayer = this.state.currentPlayer;

    players[currentPlayer].legalMoves = this.getLegalMoves(currentPlayer);

    this.setState({
      players: players
    }, () => {
      this.checkGameEnd();
    }) // callback executes after this.state.legalMoves is updated
  }

  checkGameEnd = () => {
    const currentPlayer = this.state.currentPlayer
    const otherPlayer = this.otherPlayer(currentPlayer);
    const otherPlayerMoves = this.getLegalMoves(otherPlayer);

    if (this.state.players[currentPlayer].legalMoves === 0) {
      if (otherPlayerMoves === 0) {
        this.state.stage.onGameEnd();
      }
      else {
        this.state.turn.onMove();
      }
    }
  }

  /* ============== */
  /* Move functions */
  /* ============== */

  playDisc = (sq) => {
    const currentPlayer = this.state.currentPlayer;

    // Change status of square to current player (which will add player disc)
    sq.status = currentPlayer;
  
    for (let i = -1; i <= 1; i++) {
      for (let j = -1; j <= 1; j++) {
  
        if ((i === 0 && j === 0)) {
          continue;
        }
  
        let currentX = sq.row + i;
        let currentY = sq.column + j;
  
        if (!this.spaceOnBoard(currentX, currentY)) {
          continue;
        }
  
        let currentID = ((currentX - 1) * 8) + currentY - 1;
        let currentSquare = this.state.squares[currentID];
  
        if (currentSquare.status === this.otherPlayer(currentPlayer)) {
          let x = 2;
          let update = false;
          let updateDiscs = [];
  
          while (this.spaceOnBoard(currentX, currentY)) {
            if (currentSquare.status === currentPlayer) {
              update = true;
              break;
            }
            else if ((currentSquare.status === null) || (currentSquare.status === "legal")) {
              break;
            }
            updateDiscs.push(currentID);
    
            currentX = sq.row + (i * x);
            currentY = sq.column + (j * x);
  
            currentID = ((currentX - 1) * 8) + currentY - 1;
            currentSquare = this.state.squares[currentID];
  
            x++;
          }
  
          if (update) {
            updateDiscs.forEach(id => {
              let allSquares = this.state.squares;
              allSquares[id].status = currentPlayer;

              this.setState({
                squares: allSquares
              });
            });
          }
        }
      }
    }

    this.state.turn.onMove();
  }

  /* ===================== */
  /* End of turn functions */
  /* ===================== */

  determineLeader = () => {
    const scores = [];
  
    this.state.players.forEach(p => { 
      scores.push(p.discs);
    });
  
    const highScores = scores.filter(score => score === Math.max(...scores))
    const gameLeader = scores.indexOf(Math.max(...scores));
  
    if (highScores.length > 1) {
      this.setState({
        leader: null
      });
    }
    else {
      this.setState({
        leader: gameLeader
      });
    }
  }
  
  cleanUpSquares = () => {
    let squares = this.state.squares;
    squares.forEach((sq) => {
      if (sq.status === "legal") {
        sq.status = null;
      }
    });

    this.setState({
      squares: squares
    });
  }

  setNextPlayer = () => {
    let currentPlayer = this.state.currentPlayer;

    this.setState({
      currentPlayer: (currentPlayer + 1) % 2
    }, () => this.state.turn.onBegin()) // callback executes after this.state.currentPlayer is updated
  }

  /* ================== */
  /* Game end functions */
  /* ================== */

  endGame = () => {
    this.setState({ mode: "end" });
  }

  summaryClickHandler = (button) => {
    const history = this.props.history;

    if (button === "home") {
      this.setState({ mode: "setup" });
      history.push("/");
    }
    else if (button === "view") {
      this.setState({ 
        mode: "view"
      });
    }
    else if (button === "new") {
      let players = this.state.players;
      players.forEach(p => { 
        p.color = null;
      });

      this.setState({ 
        mode: "setup",
        players: players
      });
    }
  }

  backdropClickHandler = () => {
    this.setState({ 
      mode: "view"
    });
  }

  cleanUpGame = () => {
    // this.state.squares.forEach((sq) => {
    //   sq.status = null;
    // });
  }

  /* ====================== */
  /* Render game components */
  /* ====================== */

  render() {
    return (
      <Aux>
        <Modal show={this.state.mode} clicked={this.backdropClickHandler}>
          <GameSummary
            players={this.state.players}
            playerColors={playerColors}
            currentPlayer={this.state.currentPlayer}
            leader={this.state.leader}
            clicked={this.summaryClickHandler}
          />
        </Modal>
        <Main>
          {this.state.mode === "setup" &&
            <Setup submit={this.setupSubmitHandler} alert={this.state.alert}>
              <SetupPlayer
                players={this.state.players}
                playerColors={playerColors}
                nameChanged={this.nameChangedHandler}
                colorChanged={this.colorChangedHandler}
              />
            </Setup>
          }
          {(this.state.mode === "play" || this.state.mode === "end" || this.state.mode === "view") &&
            <Aux divClass="game container">
              <Sidebar>
                <PlayerScores
                  players={this.state.players}
                  playerColors={playerColors}
                />
              </Sidebar>
              <Table>
                <Status
                  players={this.state.players}
                  playerColors={playerColors}
                  currentPlayer={this.state.currentPlayer}
                />
                <Board
                  players={this.state.players}
                  playerColors={playerColors}
                  currentPlayer={this.state.currentPlayer}
                  squares={this.state.squares}
                  squareColors={squareColors}
                  moves={this.state.moves}
                />
                <Guide
                  players={this.state.players}
                  playerColors={playerColors}
                  currentPlayer={this.state.currentPlayer}
                />
              </Table>
            </Aux>
          }
        </Main>
      </Aux>
    )
  }
}

export default Game;